<template>
  <div class="server">
    <div class="server-content">

      <el-image class="image" fit="scale-down" :src="titleList[serIndex].img"></el-image>
      <div class="server-content-right">
        <div class="title">
          {{ titleList[serIndex].title }}
        </div>
        <div class="text">
          {{ titleList[serIndex].desc }}
          <b>{{ titleList[serIndex].descBold }}</b>
          <div v-for="(item, index) in titleList[serIndex].list" :key="index">
            <span style="color: #409EFF;font-weight: 700;">{{ serIndex == 2 ? index + 1 + '.' : '·' }}</span>
            &nbsp;
            {{ item }}
          </div>
          <div v-if="titleList[serIndex].label">{{ titleList[serIndex].label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      titleList: [{
        title: '短剧备案',
        img: require("@/assets/image/01.png"),
        desc: '6月1日，国家广播电视总局下发的《关于微短剧备案最新工作提示》实施，明确微短剧实行分类分层审核，未经审核且备案的微短剧不得上网传播。这意味着一度狂飙突进的微短剧行业进入更加规范发展的时代。“微短剧备案新规是保障行业健康发展和保护消费者权益的必然举措，它的落地将促进微短剧市场优胜劣汰，让优质作品脱颖而出，促使制作方更加注重内容质量和观众体验。”洪勇表示，随着技术进步与用户需求的不断升级，微短剧有望朝着质量精品化、类型细分化、商业模式多元化方向发展，尤其是IP联动、跨界合作、互动剧等形式的创新探索，将丰富微短剧的表现形式和营利模式。同时，随着监管制度的完善，行业将逐步摆脱“狂飙”阶段，进入更健康有序的发展轨道。'
      }, {
        title: '短剧推广',
        img: require("@/assets/image/02.jpg"),
        desc: '短剧完成，没有合适的推广渠道和方向？抖音，快手，微视，各平台广告专区，提供专业指导，上各大平台推广您的巨作！‌',
        descBold: '短剧推广的简介应该简洁明了、‌突出亮点、‌制造悬念、‌强调情感共鸣、‌使用关键词，‌并适时更新。‌',
        label: '此外，‌还可以使用一些吸睛的句子来介绍短剧，‌例如“紧凑而引人入胜，‌这部短剧将让你欲罢不能！‌”或者“独特的视角，‌创新的情节，‌这部短剧将颠覆你对传统剧集的认知！‌”等。‌这些句子能够有效地吸引观众的注意，‌提高短剧的吸引力。‌',
        list: [
          '简洁明了：‌简介应该简单直接，‌用简短的话说明短剧的主题和吸引人的点，‌避免使用长句子和难懂的词，‌让观众一目了然。',
          '突出亮点：‌强调短剧的独特之处，‌如新颖的剧情、‌视觉效果等，‌让观众感到特别和与众不同，‌从而吸引更多观众。‌',
          '制造悬念：‌在简介中稍微加入一些神秘感，‌如提出一个问题或透露一个出乎意料的结果，‌激发观众的好奇心。‌',
          '强调情感共鸣：‌讲述能打动人心的故事或提出引人思考的问题，‌让观众产生情感上的共鸣。‌‌',
          '使用关键词：‌在发布时加入与短剧内容相关的关键词，‌如主题、‌风格等，‌提高短剧的曝光率。‌',
          '适时更新：‌如果有新的剧情进展，‌及时更新简介，‌吸引已观看过的观众再次观看。‌‌',
        ]
      }, {
        title: '短剧模板',
        img: require("@/assets/image/03.jpeg"),
        desc: '短剧拍摄模板，为短剧拍摄新手提供了丰富的各种类型的拍摄方案，对初学者提供了专业的方向指导。易于上手，在不同维度提供了经验与帮助。',
        list: [
          '确定拍摄风格和主题：首先，明确你想要表达的视觉风格和主题。这有助于在后续的准备工作中有明确的方向。',
          '选择拍摄场地：根据拍摄风格和主题，选择合适的室内或室外场地。如果是室外，可能需要提前踩点以确保光线和背景符合预期。‌',
          '灯光和道具准备：考虑使用什么样的灯光来营造特定的氛围或效果。同时，选择合适的道具来增强画面的视觉效果和故事性。‌',
        ]
      }],
      serIndex: 0
    };
  },
  computed: {},
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.serIndex = this.$route.query.i
      }
    }
  },
  mounted () { },
  methods: {}
};
</script>
<style lang="less" scoped>
.server {
  box-sizing: border-box;
  padding: 1vw;

  &-content {
    padding: 5vw;
    background-color: #fff;
    display: flex;

    &-right {
      .title {
        font-size: 24px;
        font-weight: 600;
      }
    }

    .image {
      width: 40%;
      flex-shrink: 0;
      margin: 0 30px 0 0;
    }

  }
}
</style>
